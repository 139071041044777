import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor() { }

  menuUpdate() {
    $('.itemMenu').removeClass('active');
    switch (location.hash) {
      case '#/administrator/dashboard':
        $(`#dashboard`).addClass('active');
        break;
      case '#/administrator/your-data':
        $(`#dados`).addClass('active');
        break;
      case '#/administrator/commissions':
        $(`#commissions`).addClass('active');
        break;
      case '#/administrator/sales':
        $(`#sales`).addClass('active');
        break;
      case '#/administrator/doubts':
        $(`#doubts`).addClass('active');
        break;
      case '#/administrator/sales-support':
        $(`#support`).addClass('active');
        break;
      case '#/agent/sales-support':
        $(`#support`).addClass('active');
        break;
      case '#/administrator/sell':
        $(`#sell`).addClass('active');
        break;
      case '#/administrator/bonuses':
        $(`#bonuses`).addClass('active');
        break;
      case '#/agent/bonuses':
        $(`#bonuses`).addClass('active');
        break;
      case '#/administrator/beneficiaries':
        $(`#beneficiaries`).addClass('active');
        break;
      case '#/administrator/commission-report':
        $(`#commission-report`).addClass('active');
        break;
      case '#/administrator/bonus-rule':
        $(`#bonus-rule`).addClass('active');
        break;
      case '#/administrator/validity-and-closing':
        $(`#validity-and-closing`).addClass('active');
        break;
      case '#/administrator/contact':
        $(`#vcontact`).addClass('active');
        break;
      case '#/administrator/users':
        $(`#users`).addClass('active');
        break;
      default:
        break;
    }
  }
}
