import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError, observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BrokersService {

  public user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));

  public httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': this.user.accessToken,
    }),
  };

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
  ) { }

  GetBrokersAll(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBroker(id): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/${id}`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBrokerAllSearch(dados): Observable<any> {
    let Data = {
      status: dados.status,
      dataInicio: dados.dataInicio,
      dataFim: dados.dataFim
    }
    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/search`,
        Data,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBrokersAssessoria(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/assessoria/all`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetSituacoes(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/situacao/all`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetEstados(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}estado`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCidades(id): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}estado/${id}`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBancos(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}banco`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetTiposContas(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}tipocontabancaria`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetSupervisores(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/supervisor/all`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCategorias(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/categoria/all`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateCorretora(form, id): Observable<any> {
    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/${id}`,
        form,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateContatos(form, id): Observable<any> {
    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/contato/${id}`,
        form,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateEnderecos(form, id): Observable<any> {
    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/endereco/${id}`,
        form,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateDadosBancarios(form, id): Observable<any> {
    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/bancario/${id}`,
        form,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateResponsavel(form, id): Observable<any> {
    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/responsavel/${id}`,
        form,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  AddDocumentoCorretora(form, id): Observable<any> {
    let formData = new FormData();
    formData.append('file', form.file);

    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/documento/${id}`,
        formData,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  AddDocumentoResponsavel(form, id): Observable<any> {
    let formData = new FormData();
    formData.append('file', form.file);

    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/responsavel/documento/${id}`,
        formData,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateDadosAcesso(form, id): Observable<any> {
    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/acesso/${id}`,
        form,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }
}
